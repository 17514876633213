@import "assets/styles/variables";

.header {
  &__container {
    height: 100%;
    // needed for ie11
    margin: 0px;
    align-content: center;
  }

  &__home-link {
    height: 100%;
    display: flex;
    align-content: flex-start;
    min-width: 195px;
    width: 195px;

    @media screen and (max-width: $md) {
      min-width: auto;
      width: unset;
    }
  }

  &__logo {
    height: 100%;
    width: auto;
    padding: 0 0.5em;

    &--holidays-horizontal {
      transform: scale(2);
      -webkit-transform: scale(2);
      padding-top: 0.2em;
      margin-left: 15px;

      @media screen and (max-width: $md) {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
      }
    }

    &--horizontal {
      margin-right: 40px;
      padding: 0;

      @media screen and (max-width: $md) {
        width: 120px;
        margin-right: 0;
      }
    }

    &--vertical {
      margin-left: 0;
    }
  }

  &__icon-button {
    color: inherit;
    padding: 6px;
    border-radius: 5px;
  }
}
