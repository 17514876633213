@import "variables";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  line-height: 1.43;
}

@import "reactflow/dist/style.css";
