.status-icon {
  display: flex;
  align-items: center;

  &__icon {
    cursor: default;
    &--pending,
    &--building,
    &--deploying,
    &--processing,
    &--validating,
    &--uploading,
    &--running,
    &--initialising,
    &--stopping,
    &--cancelled_pending {
      animation: fadeIn 1.5s infinite alternate;
    }
  }

  &__alert {
    display: flex;
    margin-right: 0.5em;
  }

  &__label {
    margin-left: 0.5em;
    font-size: 1em !important;
  }

  &__error-tooltip {
    font-size: 1em;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
}
