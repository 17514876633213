@import "assets/styles/variables";

.sidebar-org-select {
  display: flex;
  padding: 0;
  text-overflow: ellipsis;

  &__listItem {
    padding: 0;
    padding-left: 8px;
    height: 55px;
  }

  &__icon {
    cursor: pointer;
    min-width: 35px;
    justify-content: center;
  }

  &__orgNameBlock {
    overflow: hidden;
    padding: 0 10px;
    & h5 {
      cursor: pointer;
    }
  }
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0;
    * {
      border: 0;
    }
  }
}
