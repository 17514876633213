.react-flow__edges.react-flow__container {
  z-index: 100 !important;
}

.diagram-canvas {
  .notistack-SnackbarContainer {
    position: absolute;
    top: unset !important;
    margin-top: 20px;
  }
}
