@import "assets/styles/variables";

.crumb-item {
  text-decoration: none;
  opacity: 0.7;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: $fw-bold;
    opacity: 1;
  }
}
