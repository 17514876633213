@import "assets/styles/variables";

.sidebar {
  &__item {
    &.active {
      .sidebar__text > span {
        font-weight: $fw-bold;
      }

      .sidebar__submenu__text > span {
        font-weight: $fw-bold;
      }
    }
  }
  &__item__menu {
    padding-right: 8px;

    &.Mui-selected {
      .sidebar__text > span {
        font-weight: $fw-bold;
      }
    }
    .sidebar__text > span {
      text-overflow: ellipsis;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__submenu {
    display: none;
  }
  &__item__menu:hover {
    .sidebar__submenu {
      display: block;
      position: fixed;
      z-index: 10;
      background-color: white;
      display: flex;
      flex-direction: column;
    }
  }

  &__icon {
    color: inherit;
    opacity: 0.9;
    min-width: 30px;
  }

  &__chevron {
    color: inherit;
    opacity: 0.9;
    min-width: 10px;
  }
}
