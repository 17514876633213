@import "assets/styles/variables";

.radio {
  width: 100%;

  &__label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;

    &--disabled {
      filter: grayscale(1);
      cursor: default;

      .radio__wrapper {
        cursor: default;

        &:hover {
          background-color: unset;
        }
      }
    }
  }

  &__wrapper {
    padding: 9px;
    flex: 0 0 auto;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
  }

  &__custom-input {
    display: flex;
    position: relative;

    > * {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }

    .PrivateRadioButtonIcon {
      transform: scale(1);
      transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      left: 0;
      position: absolute;
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      flex-shrink: 0;
      user-select: none;
    }
  }

  &__input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
  }

  &__input:not(:checked) + &__wrapper {
    .PrivateRadioButtonIcon {
      transform: scale(0);
      transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    }
  }
}
