@import "assets/styles/variables";

.SidebarRight {
  right: 0.75rem;
  width: 320px;
  padding: 1.25rem;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (min-width: 1200px) {
    width: 500px;
  }
}
