@import "assets/styles/variables";

.form-wrapper {
  margin: 0 auto;
  max-width: 100%;

  @media screen and (min-width: $sm) {
    max-width: 90%;
  }

  @media screen and (min-width: $lg) {
    max-width: 77%;
  }

  &--small {
    margin: 0 0;
    max-width: 90%;
  }

  &--full {
    max-width: 100%;
    margin: 0 0;
  }
}
