html {
  -webkit-font-smoothing: antialiased;
}

.app {
  &__notification-icon {
    margin-right: 0.5em;
    width: 1.2em;
    height: 1.2em;
  }
}

.required-field-with-asterisk {
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    // this was added to see if this css is applied anywhere
    color: rgb(205, 72, 94);
  }
}

.MuiToolbar-gutters {
  color: inherit;
  background-color: inherit;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.notistack-MuiContent-error {
  flex-wrap: nowrap;
}

.notistack-MuiContent-success {
  flex-wrap: nowrap;
}
.notistack-MuiContent-warning {
  flex-wrap: nowrap;
}
.notistack-MuiContent-info {
  flex-wrap: nowrap;
}
