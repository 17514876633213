@import "assets/styles/variables";

.form-section {
  margin-bottom: 1em;

  &__header {
    padding: 20px;
    text-align: right;

    @media screen and (max-width: $sm) {
      border-right: none;
      text-align: left;
    }

    &--small {
      border-right: none;
      text-align: left;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.MuiAccordionDetails-root > .form-section {
  padding-bottom: 2rem;
}
