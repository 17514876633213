@import "assets/styles/variables";

.sign-up {
  display: flex;
  width: 100vw;
  height: 100vh;

  @media screen and (max-height: 700px) {
    height: auto;
  }

  &__form-section {
    flex: 4;
    padding: 2em;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    align-self: flex-start;
    width: 4em;
    height: auto;
    margin: 1.45em;
    margin-bottom: 3em;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__form {
    max-width: 340px;
    margin-top: 3em;
    overflow: auto;
    &__checkboxes-section {
      margin: 10px 0;
    }
  }

  &__presentation-section {
    flex: 5;
    padding: 5em;
    position: relative;
    background-image: url(~assets/images/registration-page.svg);
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: right;
    display: flex;
    align-items: center;

    @media screen and (max-width: $md) {
      display: none;
    }
  }

  &__selling-points {
    max-width: 50%;
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 25px;
  }

  &__selling-point {
    padding: 1em 0;
  }

  &__copyright {
    position: absolute;
    bottom: 2em;
    width: calc(100% - 6em);
    text-align: center;
  }

  &__back-button {
    width: 340px;
    margin-top: 1em;
  }
}
