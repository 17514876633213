.authentication-container {
  margin: 0 auto;
  align-items: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-top: 4em;
  padding-bottom: 1em;
  overflow-y: auto;

  &__header {
    margin-bottom: 2em;
    max-width: 340px;
    max-height: 200px;
  }

  &__info {
    width: 340px;
    align-items: flex-start;
  }

  &__logo {
    width: 90px;
    height: auto;
    text-align: center;
    overflow: unset;
    margin: 0 auto 1em;
    cursor: pointer;
  }

  &__content {
    width: 100%;
    max-width: 340px;
    overflow: visible;
  }
}
