@import "assets/styles/variables";

.textarea {
  margin: 0.5em 0;
  width: 100%;

  &__label {
    position: relative;
    line-height: 1;
  }

  &__input {
    font-size: 1em;
    padding: 0.6em 0.8em;
    border-radius: 4px;
    color: inherit;
    outline: none;
    width: 100%;

    &:focus {
      border-width: 2px;
    }
  }
}
