@import "assets/styles/variables";

.sidebar-project-select {
  display: flex;
  padding: 0;

  &__listItem {
    padding: 0px 0px 0px 8px;
    height: 45px;
  }

  &__icon {
    min-width: 35px;
    justify-content: center;
  }

  &__projectNameBlock {
    padding: 0 10px;
    overflow: hidden;
    & h5 {
      cursor: pointer;
    }
  }

  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    margin-bottom: 0;
    * {
      border: 0;
    }
  }
}
