.organization-subscription {
  &__section {
    display: flex;
    padding: 0.5em 1.5em;
  }
  &__info {
    font-weight: 600;
    margin-top: 1em;
  }
  &__history {
    margin: 1.5em;
    padding: 10px;
  }
}
