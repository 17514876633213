/**
FONTS
**/
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 600;
$fs: 14px;

/**
BREAKPOINTS
**/
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1440px;

$shadow-primary: 2px 2px 4px rgba(0, 0, 0, 0.25);
