.Link {
  &:hover {
    text-decoration: underline;
  }

  &CardHeader {
    &:hover {
      text-decoration: underline;
    }
  }
}
