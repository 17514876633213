@import "assets/styles/variables";

.sidebar-support {
  &__item {
    &__subscription {
      padding: 0;
      margin-bottom: 5px;
    }
  }

  &__icon {
    color: inherit;
    opacity: 0.9;
    min-width: 35px;
  }
}
