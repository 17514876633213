.batch-request-create {
  &__container {
    width: 100%;
    flex-direction: column;
  }

  &__timeout {
    margin: 5px;
  }

  &__card {
    width: 100%;
    margin: 5px;
  }

  &__item {
    flex-grow: 1;
  }

  &__notification-group {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
