.file-field {
  &__vertical-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    margin-bottom: 0.2em;
  }

  &__filename {
    display: grid;
  }

  &__input {
    display: none;
  }

  &__code-covered-box {
    position: absolute;
    width: 300px;
    text-align: center;
    font-size: 0.9em;
    padding: 3px;
    font-weight: 500;
    margin-inline-start: 10px;
    line-height: 17px;
  }
}
