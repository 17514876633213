@import "assets/styles/variables";

.requests-page-configuration {
  &__preview {
    margin: 0 auto;
    flex: 4;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__divider {
    margin-top: 16px;
    margin-bottom: 24px;
  }
}

.form-container {
  &__button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  &__submit-button {
    margin-right: 10px;
    margin-left: auto;
  }
}
